/** @format */
import idx from 'idx';
import React from 'react';
import propTypes from 'prop-types';

import { checkPadding, formatKeys } from '../../../lib/utilities';

const DevAddress = ({ item }) => {
  return (
    <>
      {idx(item, _ => (item.device ? _.device.devaddr : _.devaddr)) ===
      undefined
        ? '---'
        : idx(item, _ =>
            item.device
              ? formatKeys(_.device.devaddr)
              : formatKeys(checkPadding(_.devaddr, 8)),
          )}
    </>
  );
};

DevAddress.propTypes = {
  item: propTypes.string,
};

export { DevAddress };
