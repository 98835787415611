/** @format */

import idx from 'idx';
import React from 'react';
import { Label, Table } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { PAGES_PATH } from '../../../lib/variables';
import PropTypes from 'prop-types';

const DefaultLabel = ({ item, path }) => {
  // eslint-disable-next-line no-console
  console.log('DefaultLabel', item);

  const { id, label, apiVersion = null } = item;

  return (
    <Table.Cell key={`row-${id}-label`} id={`row-${id}-label`}>
      <Link to={`${PAGES_PATH}/${path}/${idx(item, _ => _.id)}`}>
        <strong>
          {label || 'no name'}{' '}
          {apiVersion && apiVersion !== '2' && (
            <Label color="red" size="mini">
              v{apiVersion}
            </Label>
          )}
        </strong>
      </Link>
    </Table.Cell>
  );
};

DefaultLabel.propTypes = {
  path: PropTypes.string.isRequired,
  item: PropTypes.object.isRequired,
};

export { DefaultLabel };
