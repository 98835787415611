/** @format */
import React from 'react';

import PropTypes from 'prop-types';

const Devices = ({ item, path }) => {
  if (path === 'application') {
    return <>{item.devices || 0}</>;
  } else if (path === 'multicast') {
    return <>{item.devices || 0}</>;
  } else {
    return <>{'-'}</>;
  }
};

Devices.propTypes = {
  item: PropTypes.object.isRequired,
  path: PropTypes.string.isRequired,
};

export { Devices };
