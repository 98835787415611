/** @format */

import React from 'react';
import propTypes from 'prop-types';

import { Link } from 'react-router-dom';
import { EMPTY_STRING_VALUE } from '../../../lib/utilities';

import { userHasRole } from '../../../lib/auth';
import { PAGES_PATH } from '../../../lib/variables';

const Owner = ({ UserId = null, username = null }) => {
  if (userHasRole('admin')) {
    return (
      <Link to={`${PAGES_PATH}/user/${UserId}`}>
        {`${username || EMPTY_STRING_VALUE}`}
      </Link>
    );
  } else {
    return `${EMPTY_STRING_VALUE}`;
  }
};

Owner.propTypes = {
  UserId: propTypes.number,
  username: propTypes.string,
};

export { Owner };
