/** @format */
import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import PropTypes from 'prop-types';

import { PAGES_PATH } from '../../../lib/variables';

const Actions = ({ item, head, path, onClickDownlink, onClickDelete }) => {
  return (
    <>
      {head.actions.includes('downlink') ? (
        <Link to="#" onClick={() => onClickDownlink(item)}>
          <FontAwesomeIcon icon="paper-plane" />
        </Link>
      ) : (
        ''
      )}
      <Link to={`${PAGES_PATH}/${path}/${item.id}/edit`}>
        <FontAwesomeIcon icon="edit" />
      </Link>
      <Link to="#" onClick={() => onClickDelete(item)}>
        <FontAwesomeIcon icon="trash-alt" />
      </Link>
    </>
  );
};

Actions.propTypes = {
  item: PropTypes.object,
  head: PropTypes.object,
  path: PropTypes.string,
  onClickDownlink: PropTypes.func,
  onClickDelete: PropTypes.func,
};

export { Actions };
