/** @format */

import idx from 'idx';
import React from 'react';
import { Label } from 'semantic-ui-react';

import PropTypes from 'prop-types';

const MacVersion = ({ item }) => {
  return (
    <>
      {idx(item, _ => (
        <Label color={'teal'}>{item.macVersion ? _.macVersion : '-'}</Label>
      ))}
    </>
  );
};

MacVersion.propTypes = {
  item: PropTypes.object,
};

export { MacVersion };
