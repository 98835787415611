/**
 * /* eslint-disable no-unused-expressions
 *
 * @format
 */

import idx from 'idx';
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import SVG from 'react-inlinesvg';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Button, Header, Input, Label, Modal } from 'semantic-ui-react';
import {
  fetchApplicationsSharedBy,
  destroyApplicationsSharedBy,
} from '../../actions/applications';
import { deleteApplicationShare } from '../../actions/applicationShare';
import { fetchUsers, fetchUsersOptions } from '../../actions/users';
import DefaultTable from '../../components/Utils/DefaultTable';
import { userHasRole } from '../../lib/auth';

import { DEFAULT_ELEMENTS_PER_PAGE } from '../../lib/variables';

const mapStateToProps = state => ({
  applicationsSharedBy: state.applicationsSharedBy,
  applications: state.applications,
  column: state.devices.column,
  direction: state.devices.direction,
  users: state.users || {},
  usersOptions: state.usersOptions.options || [],
});

const mapDispatchToProps = dispatch => ({
  fetchApplicationsSharedBy: (
    params = { qs: `limit=${DEFAULT_ELEMENTS_PER_PAGE}` },
  ) => {
    dispatch(fetchApplicationsSharedBy(params));
  },
  deleteApplicationShare: application => {
    dispatch(deleteApplicationShare(application));
  },
  destroyApplicationsSharedBy: () => {
    dispatch(destroyApplicationsSharedBy());
  },
  fetchUsers: (username, params = { qs: '' }) => {
    dispatch(fetchUsers(username, params));
  },
  fetchUsersOptions: () => {
    dispatch(fetchUsersOptions());
  },
});

class ApSTable extends Component {
  constructor(props) {
    super(props);
    this.props = props;

    this.state = {
      formGroup: [
        {
          width: 3,
          label: 'Label',
          name: 'label',
          type: 'text',
        },
      ],
      modalOpen: false,
      share: null,
      label: null,
      check: null,
      applicationShared: [],
      numberOfDeviceForApplication: [],
      search: '',
      sortModel: {
        label: '',
        direction: null /* asc || desc || null */,
      },
      users: [],
    };

    this.handleClose = this.handleClose.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.handleDeleteModal = this.handleDeleteModal.bind(this);
    this.fetch = this.fetch.bind(this);
  }

  componentDidMount() {
    this.props.fetchApplicationsSharedBy();
    if (userHasRole('admin')) {
      this.props.fetchUsersOptions();
      this.props.fetchUsers({ qs: 'limit=10000' });
    }
  }

  fetch(params) {
    const { qs } = params;

    this.props.fetchApplicationsSharedBy({ qs });
  }

  handleClose() {
    this.setState({
      modalOpen: false,
      share: null,
      label: null,
      check: null,
    });
  }

  handleDeleteModal(share) {
    this.setState({ modalOpen: true, share, label: share.label });
  }

  handleDelete() {
    this.setState({
      modalOpen: false,
      share: null,
      label: null,
      check: null,
    });
  }

  handleChange(e, { name, value }) {
    this.setState({ [name]: value });
  }

  render() {
    const { check, formGroup, share, label } = this.state;
    const { users, usersOptions, applicationsSharedBy } = this.props;

    return (
      <Fragment>
        <DefaultTable
          headers={[
            { name: 'Appid', key: 'application.appid' },
            {
              sortable: true,
              name: 'Label',
              sortBy: 'label',
              key: 'label',
            },
            { name: 'Sharing', key: 'sharing' },
            { name: 'Devices', key: 'devices' },
            { name: 'Action', key: 'remove' },
          ]}
          path={'application'}
          typeOfSharing={'By'}
          tableType={'sharing'}
          users={users}
          formGroup={formGroup}
          usersOptions={usersOptions}
          handleDeleteModal={this.handleDeleteModal}
          fetch={this.fetch}
          items={applicationsSharedBy}
        />
        <Modal
          open={this.state.modalOpen}
          onClose={this.handleClose}
          basic
          size="small"
        >
          <div style={{ width: '40%' }}>
            <SVG src="/assets/images/robotRYS.svg" />
          </div>
          <Header as="h1" content="Are you sure??" />
          <Modal.Content>
            <h3>
              This action will remove a share for{' '}
              {idx(share, _ => _.guest.username)}
            </h3>
            <h4>WARNING, THIS ACTION IS NOT REVERSIBLE!</h4>
            <p>To proceed, please fill the field with the application label</p>
            <Label size="big" color="orange" style={{ marginBottom: '2em' }}>
              {idx(share, _ => _.label)}
            </Label>
            <div>
              <Input
                name="check"
                placeholder="..."
                width={8}
                onChange={this.handleChange}
              />
            </div>
          </Modal.Content>
          <Modal.Actions>
            <Button
              onClick={this.handleDelete}
              color="red"
              disabled={label !== check}
            >
              Proceed
            </Button>
            <Button onClick={this.handleClose}>Cancel</Button>
          </Modal.Actions>
        </Modal>
      </Fragment>
    );
  }
}

ApSTable.propTypes = {
  users: PropTypes.object,
  usersOptions: PropTypes.array,
  fetchApplicationsSharedBy: PropTypes.func,
  applicationsSharedBy: PropTypes.object,
  destroyApplicationsSharedBy: PropTypes.func,
  destroyDeviceShare: PropTypes.func,
  deleteApplicationShare: PropTypes.func,
  fetchUsers: PropTypes.func,
  fetchUsersOptions: PropTypes.func,
};

const ApplicationsTableSharedBy = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ApSTable),
);

export { ApplicationsTableSharedBy };
