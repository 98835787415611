/** @format */

import React from 'react';
import { toast } from 'react-semantic-toasts';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import PropTypes from 'prop-types';

const CopyToClipboardWithMessage = ({ copy, message, children }) => {
  const notify = description => {
    toast({
      title: 'COPIED TO CLIPBOARD',
      description,
      color: 'teal',
      type: 'success',
      icon: 'copy',
    });
  };

  return (
    <span className="copy-to-clipboard" onClick={() => notify(message)}>
      <CopyToClipboard text={copy}>
        <span>{children}</span>
      </CopyToClipboard>
    </span>
  );
};

CopyToClipboardWithMessage.propTypes = {
  copy: PropTypes.string.isRequired, // value to copy to clipboard
  message: PropTypes.string.isRequired, // message to display in toast
  children: PropTypes.node.isRequired,
};

export { CopyToClipboardWithMessage };
