/** @format */
import React from 'react';
import { Icon } from 'semantic-ui-react';

import PropTypes from 'prop-types';

const SupportsClassC = ({ item }) => {
  const { supportsClassC = false } = item;

  const name = supportsClassC ? 'check' : 'cancel';
  const color = supportsClassC ? 'green' : 'red';

  return (
    <>
      <Icon color={color} name={name} size="large" />
    </>
  );
};

SupportsClassC.propTypes = {
  item: PropTypes.object,
};

export { SupportsClassC };
