/** @format */

import idx from 'idx';
import React from 'react';
import { Table } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import { PAGES_PATH } from '../../../lib/variables';

const ApplicationSharingWithLabel = ({ item }) => {
  // eslint-disable-next-line no-console
  console.log('ApplicationSharingWithLabel', item);

  return (
    <Table.Cell key={`row-${item.id}-label`} id={`row-${item.id}-label`}>
      <Link to={`${PAGES_PATH}/application/${idx(item, _ => _.id)}`}>
        <strong>{idx(item, _ => _.label || 'no name')} </strong>
      </Link>
    </Table.Cell>
  );
};

ApplicationSharingWithLabel.propTypes = {
  item: PropTypes.object.isRequired,
};

export { ApplicationSharingWithLabel };
