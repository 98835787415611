/** @format */
import idx from 'idx';
import React from 'react';
import { Link } from 'react-router-dom';
import propTypes from 'prop-types';

import { PAGES_PATH } from '../../../lib/variables';

const Id = ({ item, path }) => {
  return (
    <Link to={`${PAGES_PATH}/${path}/${idx(item, _ => _.id)}`}>
      <strong>{idx(item, _ => _.id)}</strong>
    </Link>
  );
};

Id.propTypes = {
  item: propTypes.object,
  path: propTypes.string,
};

export { Id };
