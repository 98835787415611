/** @format */

import idx from 'idx';
import React from 'react';
import { Table } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import { PAGES_PATH } from '../../../lib/variables';

const DeviceSharingWithLabel = ({ item }) => {
  // eslint-disable-next-line no-console
  console.log('DeviceSharingWithLabel', item);

  const { DeviceId = '' } = item;

  return (
    <Table.Cell key={`row-${item.id}-label`} id={`row-${item.id}-label`}>
      <Link to={`${PAGES_PATH}/device/${DeviceId}`}>
        <strong>{idx(item, _ => _.label || 'no name')} </strong>
      </Link>
    </Table.Cell>
  );
};

DeviceSharingWithLabel.propTypes = {
  item: PropTypes.object.isRequired,
};

export { DeviceSharingWithLabel };
