/** @format */
import React from 'react';
import { Icon } from 'semantic-ui-react';

import PropTypes from 'prop-types';

const SupportsClassB = ({ item }) => {
  const { supportsClassB = false } = item;

  const name = supportsClassB ? 'check' : 'cancel';
  const color = supportsClassB ? 'green' : 'red';

  return (
    <>
      <Icon color={color} name={name} size="large" />
    </>
  );
};

SupportsClassB.propTypes = {
  item: PropTypes.object.isRequired,
};

export { SupportsClassB };
