/** @format */

import React from 'react';
import { Link } from 'react-router-dom';

import { userHasRole } from '../../lib/auth';
import { CopyToClipboardWithMessage } from './CopyToClipboardWithMessage';
import PropTypes from 'prop-types';

const FollowLink = ({ to, copy, message, children }) => (
  <>
    {userHasRole('admin') ? (
      <Link to={to}>{children}</Link>
    ) : (
      <CopyToClipboardWithMessage copy={copy} message={message}>
        {children}
      </CopyToClipboardWithMessage>
    )}
  </>
);

FollowLink.propTypes = {
  to: PropTypes.string.isRequired, // url to follow
  copy: PropTypes.string.isRequired, // value to copy to clipboard
  message: PropTypes.string.isRequired, // message to display in toast when copied
  children: PropTypes.node.isRequired,
};

export { FollowLink };
