/** @format */

import idx from 'idx';
import React from 'react';
import { Table } from 'semantic-ui-react';
import PropTypes from 'prop-types';

import { FollowLink } from '../FollowLink';
import { PAGES_PATH } from '../../../lib/variables';

const DeviceSharingByLabel = ({ item }) => {
  // eslint-disable-next-line no-console
  console.log('DeviceSharingByLabel', item);

  const { DeviceId = '' } = item;

  return (
    <Table.Cell key={`row-${item.id}-label`} id={`row-${item.id}-label`}>
      <FollowLink
        to={`${PAGES_PATH}/device/${DeviceId}`}
        message={`id: ${DeviceId}`}
        copy={DeviceId}
      >
        <strong>{idx(item, _ => _.label || 'no name')} </strong>
      </FollowLink>
    </Table.Cell>
  );
};

DeviceSharingByLabel.propTypes = {
  item: PropTypes.object.isRequired,
};

export { DeviceSharingByLabel };
