/** @format */
import React from 'react';
import { Label } from 'semantic-ui-react';

import PropTypes from 'prop-types';

const Status = ({ item }) => {
  const {
    status: { activated = false },
  } = item;

  const label = activated ? 'UP' : 'DOWN';
  const color = activated ? 'green' : 'red';

  return (
    <>
      <Label size="mini" color={color}>
        {label}
      </Label>
    </>
  );
};

Status.propTypes = {
  item: PropTypes.object,
};

export { Status };
