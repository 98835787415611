/**
 *
 *
 * @format
 */
/* eslint-disable no-unused-vars */
/* eslint-disable array-callback-return */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import idx from 'idx';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import {
  Checkbox,
  Container,
  Pagination,
  Table,
  Label,
  Icon,
} from 'semantic-ui-react';
import { toast } from 'react-semantic-toasts';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import { userHasRole } from '../../lib/auth';
import {
  EMPTY_STRING_VALUE,
  EMPTY_TABLE_MESSAGE,
  formatKeys,
  formatNormalString,
  searchMethod,
  checkPadding,
} from '../../lib/utilities';
import { DEFAULT_ELEMENTS_PER_PAGE, PAGES_PATH } from '../../lib/variables';
import {
  Id,
  Owner,
  Deveui,
  DefaultLabel,
  Devices,
  DeviceSharingByLabel,
  DeviceSharingWithLabel,
  ApplicationSharingByLabel,
  ApplicationSharingWithLabel,
  DevAddress,
  Status,
  Type,
  MacVersion,
  Supports32bitFCnt,
  SupportsClassB,
  SupportsClassC,
  Action,
  Actions,
  SharingWith,
  SharingBy,
  Application,
} from './Fields';
import SearchBox from '../../ui/SearchBox';
import PropTypes from 'prop-types';

import './DefaultTable.css';

const notify = description => {
  toast({
    title: 'COPIED TO CLIPBOARD',
    description,
    color: 'teal',
    type: 'success',
    icon: 'copy',
  });
};

export default class DefaultTable extends Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      selected: [],
      sharingTable: true,
      sortModel: {
        label: '',
        direction: null /* asc || desc || null */,
      },
      offset: 0,
      limit: DEFAULT_ELEMENTS_PER_PAGE,
      formGroup: [],
      searchModel: {},
      applicationsOptions: [],
    };
    this.handleCheck = this.handleCheck.bind(this);
    this.handleDownlinkModal = this.handleDownlinkModal.bind(this);
    this.handleDeleteModal = this.handleDeleteModal.bind(this);
    this.fetch = this.fetch.bind(this);
    this.findTheLabel = this.findTheLabel.bind(this);
    this.onSearch = this.onSearch.bind(this);

    this.searchBox = {
      doFetch: queryParams => {
        const qs = queryParams ? queryParams.join('&') : '';
        this.fetch({ qs });
      },

      handlePageChange: (e, { activePage }) => {
        const { items, offset } = this.props;
        const { searchModel, sortModel, limit } = this.state;

        const newOffset = (activePage - 1) * items.limit;

        const currOffset = newOffset || (offset > 0 ? offset : null);

        const queryParams = searchMethod.genQs(
          searchModel,
          sortModel,
          currOffset,
          limit,
        );
        this.setState({ offset: newOffset });
        this.searchBox.doFetch(queryParams);
      },

      handleSearch: (e, el, newSortModel) => {
        const { searchModel, sortModel, limit } = this.state;

        let currSortModel = null;

        if (newSortModel) currSortModel = { ...newSortModel };
        else if (sortModel.label) currSortModel = { ...sortModel };

        const queryParams = searchMethod.genQs(
          searchModel,
          currSortModel,
          null,
          limit,
        );
        if (newSortModel) this.setState({ sortModel: newSortModel });
        this.searchBox.doFetch(queryParams);

        // trigger export params
        this.onSearch(searchModel);
      },

      handleSearchChange: (e, { name, value }) => {
        const newSearchModel = { ...this.state.searchModel };
        const newFormGroup = [...this.state.formGroup];
        const formIdx = newFormGroup.map(x => x.name).indexOf(name);

        switch (value) {
          case null:
          case '':
            this.setState({
              ...this.state,
              applicationsOptions:
                this.props.applications && 'rows' in this.props.applications
                  ? this.props.applications.rows
                  : [],
            });

            delete newSearchModel[name];
            if (userHasRole('admin')) {
              if (name === 'appid') {
                delete newSearchModel.uid;
                const uidIdx = newFormGroup.map(x => x.name).indexOf('uid');
                if (uidIdx >= 0) newFormGroup[uidIdx].selected = '';
              } else if (name === 'uid') {
                delete newSearchModel.appid;
                const appidIdx = newFormGroup.map(x => x.name).indexOf('appid');
                if (appidIdx >= 0) newFormGroup[appidIdx].selected = '';
              }
            }

            break;

          default:
            switch (name) {
              case 'deveui':
                newSearchModel[name] = encodeURIComponent(
                  formatNormalString(value, ':'),
                );
                break;

              case 'devaddr':
                newSearchModel[name] = encodeURIComponent(
                  formatNormalString(value, ':'),
                );
                break;

              case 'appid':
                // eslint-disable-next-line no-case-declarations
                const [appid, uid] = value.split('-');
                newSearchModel.appid = encodeURIComponent(appid);
                if (userHasRole('admin')) {
                  const uidIdx = newFormGroup.map(x => x.name).indexOf('uid');
                  if (uidIdx >= 0)
                    newFormGroup[uidIdx].selected = Number.isNaN(+uid)
                      ? null
                      : +uid;
                  newSearchModel.uid = encodeURIComponent(uid);
                }
                break;

              case 'uid':
                // se uid cambia, deseleziona appid
                if (userHasRole('admin')) {
                  delete newSearchModel.appid;
                  const appidIdx = newFormGroup
                    .map(x => x.name)
                    .indexOf('appid');
                  if (appidIdx >= 0) newFormGroup[appidIdx].selected = '';

                  this.setState({
                    ...this.state,
                    applicationsOptions:
                      this.props.applications &&
                      'rows' in this.props.applications
                        ? this.props.applications.rows.filter(a => {
                            if (a.UserId === value) return true;
                            return false;
                          })
                        : [],
                  });
                }
              // non usare break, perché deve fare operazione default sotto
              // eslint-disable-next-line no-fallthrough
              default:
                newSearchModel[name] = encodeURIComponent(value);
            }
        }

        if (formIdx >= 0) newFormGroup[formIdx].selected = value;
        this.setState({
          searchModel: newSearchModel,
          formGroup: newFormGroup,
        });
      },

      handleSort: clickedColumn => () => {
        const { sortModel } = this.state;
        let direction = null;
        let label = '';
        if (sortModel.label === clickedColumn) {
          switch (sortModel.direction) {
            case 'asc':
              direction = 'desc';
              label = clickedColumn;
              break;
            case 'desc':
              direction = null;
              break;
            default:
              direction = 'asc';
              label = clickedColumn;
          }
        } else {
          direction = 'asc';
          label = clickedColumn;
        }
        this.searchBox.handleSearch(null, null, {
          ...sortModel,
          label,
          direction,
        });
      },

      handleSorted: column => {
        const { sortModel } = this.state;
        if (sortModel.direction && sortModel.label === column) {
          return sortModel.direction === 'asc' ? 'descending' : 'ascending';
        }
        return null;
      },
    };
  }

  componentDidMount() {
    if (this.props) {
      const newState = {
        selected: this.props.selected,
        sharingTable: this.props.sharingTable,
        formGroup: this.props.formGroup,
        applicationsOptions: [],
      };
      this.setState({
        ...this.state,
        ...newState,
      });
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      prevProps.formGroup !== this.props.formGroup ||
      prevProps.applications != this.props.applications ||
      this.props.selected != prevProps.selected ||
      this.props.deviceForApplication != prevProps.deviceForApplication
    ) {
      this.setState({
        ...this.state,
        formGroup: this.props.formGroup,
        applicationsOptions:
          'applications' in this.props && 'rows' in this.props.applications
            ? this.props.applications.rows
            : [],
        selected: this.props.selected,
        deviceForApplication: this.props.deviceForApplication,
      });
    }
  }

  fetch(qs) {
    this.props.fetch(qs);
  }

  handleCheck(id, owner) {
    // this.props.selected;
    this.props.handleCheck(id, owner);
  }

  handleDownlinkModal(row) {
    this.props.handleDownlinkModal(row);
  }

  onSearch(searchModel) {
    this.props.onSearch(searchModel);
  }

  handleDeleteModal(row) {
    this.props.handleDeleteModal(row);
  }

  findTheLabel(appid, userID) {
    return this.props.applications.rows.filter(
      element => element.appid === appid && element.UserId === userID,
    );
  }

  render() {
    const { selected, formGroup, applicationsOptions } = this.state;
    const {
      // users,
      items,
      headers,
      typeOfSharing,
      devicesNumberList,
      path,
      deviceForApplication,
      usersOptions,
      tableType,
    } = this.props;

    return (
      <div>
        {formGroup && formGroup.length && (
          <SearchBox
            formGroup={formGroup}
            usersOptions={usersOptions || []}
            applicationsOptions={applicationsOptions || []}
            startSearch={this.searchBox.handleSearch}
            updateSearch={this.searchBox.handleSearchChange}
          />
        )}
        {items.rows && items.rows.length > 0 ? (
          <>
            <Table sortable celled={false} style={{ textAlign: 'center' }}>
              <Table.Header>
                <Table.Row id={'headers-row'}>
                  {headers.length > 0 &&
                    headers.map(head =>
                      !head.admin && ['Owner'].includes(head.name) ? null : (
                        <Table.HeaderCell
                          key={`header-${head.key || head.name}`}
                          id={head.key}
                          className={!head.sortable ? 'unsortable' : ''}
                          sorted={
                            head.sortable
                              ? this.searchBox.handleSorted(head.sortBy)
                              : null
                          }
                          onClick={
                            head.sortable
                              ? this.searchBox.handleSort(head.sortBy)
                              : null
                          }
                        >
                          {head.name}
                        </Table.HeaderCell>
                      ),
                    )}
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {idx(items.rows, accessory =>
                  accessory.map((row, index) => (
                    <>
                      <Table.Row key={`row-${row.id}`} id={`row-${row.id}`}>
                        {headers.length > 0 &&
                          // eslint-disable-next-line consistent-return
                          headers.map(head => {
                            switch (head.name) {
                              case '':
                                return (
                                  <Table.Cell
                                    key={`row-${row.id}-select`}
                                    id={`row-${row.id}-select`}
                                  >
                                    <Checkbox
                                      checked={selected.indexOf(row.id) !== -1}
                                      onChange={() =>
                                        this.handleCheck(row.id, row.UserId)
                                      }
                                    />
                                  </Table.Cell>
                                );
                              case 'Label':
                                if (
                                  path === 'application' &&
                                  tableType === 'sharing' &&
                                  typeOfSharing === 'By'
                                ) {
                                  return (
                                    <ApplicationSharingByLabel item={row} />
                                  );
                                } else if (
                                  path === 'application' &&
                                  tableType === 'sharing' &&
                                  typeOfSharing === 'With'
                                ) {
                                  return (
                                    <ApplicationSharingWithLabel item={row} />
                                  );
                                } else if (
                                  path === 'device' &&
                                  tableType === 'sharing' &&
                                  typeOfSharing === 'By'
                                ) {
                                  return <DeviceSharingByLabel item={row} />;
                                } else if (
                                  path === 'device' &&
                                  tableType === 'sharing' &&
                                  typeOfSharing === 'With'
                                ) {
                                  return <DeviceSharingWithLabel item={row} />;
                                } else {
                                  return (
                                    <DefaultLabel item={row} path={path} />
                                  );
                                }
                              case 'Owner':
                                return (
                                  <Table.Cell
                                    key={`row-${row.id}-owner-admin`}
                                    id={`row-${row.id}-owner-admin`}
                                  >
                                    <Owner
                                      UserId={idx(row, _ => _.UserId)}
                                      username={idx(
                                        row,
                                        _ =>
                                          `${row.owner.username}` ||
                                          EMPTY_STRING_VALUE,
                                      )}
                                    />
                                  </Table.Cell>
                                );
                              case 'ID':
                                return (
                                  <Table.Cell key={`row-${row.id}-id`}>
                                    <Id item={row} path={path} />
                                  </Table.Cell>
                                );
                              case 'Appid':
                                return (
                                  <Table.Cell key={`row-${row.id}-id`}>
                                    {userHasRole('admin') ||
                                    typeOfSharing === 'With' ? (
                                      <Link
                                        to={`${PAGES_PATH}/${path}/${idx(
                                          row,
                                          !typeOfSharing
                                            ? _ => _.id
                                            : _ => _.ApplicationId,
                                        )}`}
                                      >
                                        <strong>
                                          {idx(
                                            row,
                                            _ => _.appid || _.application.appid,
                                          )}
                                        </strong>
                                      </Link>
                                    ) : (
                                      <strong>
                                        {idx(
                                          row,
                                          _ => _.appid || _.application.appid,
                                        )}
                                      </strong>
                                    )}
                                  </Table.Cell>
                                );
                              case 'Sharing':
                                if (typeOfSharing === 'With') {
                                  return (
                                    <Table.Cell
                                      key={`row-${row.id}-owner}`}
                                      id={`row-${row.id}`}
                                    >
                                      <SharingWith item={row} path={path} />
                                    </Table.Cell>
                                  );
                                }
                                return (
                                  <Table.Cell
                                    key={`row-${row.id}-sharing`}
                                    id={`row-${row.id}-sharing`}
                                  >
                                    <SharingBy item={row} path={path} />
                                  </Table.Cell>
                                );
                              case 'Application':
                                if (
                                  'device' in row &&
                                  'application' in row.device
                                ) {
                                  return (
                                    <Table.Cell
                                      key={`row-${row.id}-application}`}
                                      id={`row-${row.id}-application}`}
                                    >
                                      <Application item={row} />
                                    </Table.Cell>
                                  );
                                } else if ('application' in row) {
                                  return (
                                    <Table.Cell
                                      key={`row-${row.id}-application}`}
                                      id={`row-${row.id}-application}`}
                                    >
                                      <Link
                                        to={`${PAGES_PATH}/application/${row.application.id}`}
                                      >
                                        {idx(
                                          row.application,
                                          _ => _.label || EMPTY_STRING_VALUE,
                                        )}{' '}
                                        {idx(
                                          row.application,
                                          _ =>
                                            _.apiVersion &&
                                            _.apiVersion !== '2' && (
                                              <Label color="red" size="mini">
                                                v{_.apiVersion}
                                              </Label>
                                            ),
                                        )}
                                      </Link>
                                    </Table.Cell>
                                  );
                                } else {
                                  return (
                                    <Table.Cell
                                      key={`row-${row.id}-application}`}
                                      id={`row-${row.id}-application}`}
                                    >
                                      {EMPTY_STRING_VALUE}
                                    </Table.Cell>
                                  );
                                }
                              case 'DevEui':
                                return (
                                  <Table.Cell
                                    key={`row-${row.id}-deveui}`}
                                    id={`row-${row.id}-deveui}`}
                                    textAlign="center"
                                  >
                                    <Deveui item={row} />
                                  </Table.Cell>
                                );
                              case 'Dev Address':
                                return (
                                  <Table.Cell
                                    key={`row-${row.id}-devaddr`}
                                    id={`row-${row.id}-devaddr`}
                                  >
                                    <DevAddress item={row} />
                                  </Table.Cell>
                                );
                              case 'Status':
                                return (
                                  <Table.Cell
                                    key={`row-${row.id}-devicestatus`}
                                  >
                                    <Status item={row} />
                                  </Table.Cell>
                                );
                              case 'Devices':
                                return (
                                  <Table.Cell key={`row-${row.id}-devices`}>
                                    <Devices item={row} path={path} />
                                  </Table.Cell>
                                );
                              case 'Type':
                                return (
                                  <Table.Cell
                                    key={`row-${row.id}-type}`}
                                    id={`row-${row.id}-type}`}
                                  >
                                    <Type item={row} />
                                  </Table.Cell>
                                );
                              case 'macVersion':
                                return (
                                  <Table.Cell
                                    key={`row-${row.id}-macVersion}`}
                                    id={`row-${row.id}-macVersion}`}
                                  >
                                    <MacVersion item={row} />
                                  </Table.Cell>
                                );
                              case 'supports32bitFCnt':
                                return (
                                  <Table.Cell
                                    key={`row-${row.id}-supports32bitFCnt}`}
                                    id={`row-${row.id}-supports32bitFCnt}`}
                                  >
                                    <Supports32bitFCnt item={row} />
                                  </Table.Cell>
                                );
                              case 'supportsClassB':
                                return (
                                  <Table.Cell
                                    key={`row-${row.id}-supportsClassB}`}
                                    id={`row-${row.id}-supportsClassB}`}
                                  >
                                    <SupportsClassB item={row} />
                                  </Table.Cell>
                                );
                              case 'supportsClassC':
                                return (
                                  <Table.Cell
                                    key={`row-${row.id}-supportsClassC}`}
                                    id={`row-${row.id}-supportsClassC}`}
                                  >
                                    <SupportsClassC item={row} />
                                  </Table.Cell>
                                );
                              case 'Actions':
                                return (
                                  <Table.Cell
                                    key={`row-${row.id}-actions}`}
                                    id={`row-${row.id}-actions}`}
                                    textAlign="center"
                                    style={{
                                      display: 'flex',
                                      justifyContent: 'space-around',
                                    }}
                                  >
                                    <Actions
                                      item={row}
                                      head={head}
                                      path={path}
                                      onClickDownlink={this.handleDownlinkModal}
                                      onClickDelete={this.handleDownlinkModal}
                                    />
                                  </Table.Cell>
                                );
                              case 'Action':
                                return (
                                  <Table.Cell
                                    key={`row-${row.id}-remove-action}`}
                                    style={{
                                      display: 'flex',
                                      justifyContent: 'space-around',
                                    }}
                                  >
                                    <Action
                                      item={row}
                                      onClickDelete={this.handleDeleteModal}
                                    />
                                  </Table.Cell>
                                );
                              default:
                                return null;
                            }
                          })}
                      </Table.Row>
                    </>
                  )),
                )}
              </Table.Body>
            </Table>
          </>
        ) : (
          EMPTY_TABLE_MESSAGE
        )}
        <Container textAlign="center">
          {/*
              Pagination:
              SemanticUI  => https://react.semantic-ui.com/addons/pagination/
              Web Service => https://documenter.getpostman.com/view/7664580/SW11XeM5?version=8ed314ff-c1e3-48cf-b62e-0ba509032cb9#common
              */}
          {items && items.size > items.limit && (
            <Pagination
              ellipsisItem={null}
              firstItem={null}
              lastItem={null}
              activePage={parseInt(items.offset / items.limit, 10) + 1}
              totalPages={Math.ceil(items.size / items.limit)}
              onPageChange={this.searchBox.handlePageChange}
            />
          )}
        </Container>
      </div>
    );
  }
}
DefaultTable.propTypes = {
  items: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  offset: PropTypes.func,
  selected: PropTypes.array,
  sharingTable: PropTypes.bool,
  formGroup: PropTypes.array,
  fetch: PropTypes.func,
  handleCheck: PropTypes.func,
  handleDownlinkModal: PropTypes.func,
  handleDeleteModal: PropTypes.func,
  applications: PropTypes.object,
  // users: PropTypes.object,
  headers: PropTypes.array,
  typeOfSharing: PropTypes.string,
  devicesNumberList: PropTypes.array,
  path: PropTypes.string,
  deviceForApplication: PropTypes.array,
  usersOptions: PropTypes.array,
  tableType: PropTypes.string,
  onSearch: PropTypes.func,
};

DefaultTable.defaultProps = {
  onSearch: () => {},
};
