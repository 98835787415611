/** @format */
import idx from 'idx';
import React from 'react';

import PropTypes from 'prop-types';

const Type = ({ item }) => {
  const value = idx(item, _ => (item.device ? _.device.type : _.type));

  return <>{value}</>;
};

Type.propTypes = {
  item: PropTypes.object,
};

export { Type };
