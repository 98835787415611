/** @format */

import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import SVG from 'react-inlinesvg';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Button, Header, Input, Label, Modal } from 'semantic-ui-react';
import {
  deleteMulticastGroup,
  destroyMulticastGroup,
} from '../../actions/multicastGroup';
import { fetchMulticastGroupDevices } from '../../actions/multicastGroupDevices';
import {
  destroyMulticastGroups,
  fetchMulticastGroups,
} from '../../actions/multicastGroups';
import { fetchUsers, fetchUsersOptions } from '../../actions/users';
import DefaultTable from '../../components/Utils/DefaultTable';
import { userHasRole } from '../../lib/auth';
import {
  cleanObject,
  formatNormalString,
  searchMethod,
} from '../../lib/utilities';
import { DEFAULT_ELEMENTS_PER_PAGE } from '../../lib/variables';

const mapStateToProps = state => ({
  multicastGroups: state.multicastGroups,
  users: state.users,
  multicastGroupDevices: state.multicastGroupDevices,
  usersOptions: state.usersOptions.options || [],
});

const mapDispatchToProps = dispatch => ({
  fetchMulticastGroups: (params = { qs: '' }) => {
    dispatch(fetchMulticastGroups(params));
  },
  fetchMulticastGroupDevices: (params = { qs: '' }) => {
    dispatch(fetchMulticastGroupDevices(params));
  },
  fetchUsers: (username, params = { qs: '' }) => {
    dispatch(fetchUsers(username, params));
  },
  deleteMulticastGroup: multicastGroup => {
    dispatch(deleteMulticastGroup(multicastGroup));
  },
  destroyMulticastGroups: () => {
    dispatch(destroyMulticastGroups());
  },
  destroyMulticastGroup: () => {
    dispatch(destroyMulticastGroup());
  },
  fetchUsersOptions: () => {
    dispatch(fetchUsersOptions());
  },
});

class DvTable extends Component {
  constructor(props) {
    super(props);
    this.props = props;

    this.state = {
      formGroup: [],
      offset: 0,
      limit: DEFAULT_ELEMENTS_PER_PAGE,
      searchModel: {},
      sortModel: {
        label: '',
        direction: null,
        /* asc || desc || null */
      },
      modalOpen: false,
      multicastGroup: null,
      check: null,
      devaddr: null,
      devicesList: [],
      devicesNumberList: [],
    };

    this.handleClose = this.handleClose.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.handleDeleteModal = this.handleDeleteModal.bind(this);
    this.handlePageChange = this.handlePageChange.bind(this);
    this.fetch = this.fetch.bind(this);

    this.searchBox = {
      doFetch: queryParams => {
        const qs = queryParams ? queryParams.join('&') : '';
        this.props.fetchMulticastGroups({ qs });
      },

      handlePageChange: (e, { activePage }) => {
        const { multicastGroups, offset } = this.props;
        const { searchModel, sortModel, limit } = this.state;

        const newOffset = (activePage - 1) * multicastGroups.limit;

        const currOffset = newOffset || (offset > 0 ? offset : null);

        const queryParams = searchMethod.genQs(
          searchModel,
          sortModel,
          currOffset,
          limit,
        );

        this.setState({ offset: newOffset });
        this.searchBox.doFetch(queryParams);
      },

      handleSearch: (e, el, newSortModel) => {
        const { searchModel, sortModel, limit } = this.state;

        let currSortModel = null;
        if (newSortModel) currSortModel = { ...newSortModel };
        else if (sortModel.label) currSortModel = { ...sortModel };

        const queryParams = searchMethod.genQs(
          searchModel,
          currSortModel,
          null,
          limit,
        );
        if (newSortModel) this.setState({ sortModel: newSortModel });
        this.searchBox.doFetch(queryParams);
      },

      handleSearchChange: (e, { name, value }) => {
        const { searchModel } = this.state;
        const newSearchModel = { ...searchModel };
        if (name === 'devaddr') {
          newSearchModel[name] = formatNormalString(value, ':');
        } else if (value === '') {
          delete newSearchModel[name];
        } else {
          newSearchModel[name] = encodeURIComponent(value);
        }
        this.setState({ searchModel: newSearchModel });
      },

      handleSort: clickedColumn => () => {
        const { sortModel } = this.state;
        let direction = null;
        let label = '';
        if (sortModel.label === clickedColumn) {
          switch (sortModel.direction) {
            case 'asc':
              direction = 'desc';
              label = clickedColumn;
              break;

            case 'desc':
              direction = null;
              break;

            default:
              direction = 'asc';
              label = clickedColumn;
          }
        } else {
          direction = 'asc';
          label = clickedColumn;
        }
        this.searchBox.handleSearch(null, null, {
          ...sortModel,
          label,
          direction,
        });
      },

      handleSorted: column => {
        const { sortModel } = this.state;
        if (sortModel.direction && sortModel.label === column) {
          return sortModel.direction === 'asc' ? 'descending' : 'ascending';
        }
        return null;
      },
    };
  }

  componentDidMount() {
    const { limit } = this.state;
    this.props.fetchMulticastGroups({ qs: limit > 0 ? `limit=${limit}` : '' });
    if (userHasRole('admin')) {
      this.props.fetchUsersOptions();
      this.props.fetchUsers({
        qs: 'limit=10000&not_roles[]=system&not_roles[]=admin',
      });
    }
    this.manageVisibleFilters();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { multicastGroups } = this.props;
    if (
      prevProps.multicastGroups !== multicastGroups &&
      multicastGroups &&
      multicastGroups.rows &&
      multicastGroups.rows.length > 0
    ) {
      this.getTheNumberOfDevices();
    }
  }

  manageVisibleFilters() {
    const nextFormGroup = [
      {
        width: 3,
        label: 'Label',
        name: 'label',
        type: 'text',
      },
      {
        width: 4,
        label: 'Dev Address',
        name: 'devaddr',
        type: 'text',
      },
    ];
    if (userHasRole('admin')) {
      nextFormGroup.splice(1, 0, {
        width: 4,
        label: 'Owner',
        name: 'uid',
        type: 'select',
      });
    }

    this.setState({
      ...this.state,
      formGroup: nextFormGroup,
    });
  }

  componentWillUnmount() {
    // this.props.destroyMulticastGroups();
    this.props.destroyMulticastGroup();
  }

  fetch(qs) {
    this.props.fetchMulticastGroups(qs);
  }

  handleClose() {
    this.setState({
      modalOpen: false,
      multicastGroup: null,
      devaddr: null,
      check: null,
    });
  }

  handleDeleteModal(multicastGroup) {
    this.setState({
      modalOpen: true,
      multicastGroup,
      devaddr: multicastGroup.devaddr,
    });
  }

  handleDelete() {
    const { multicastGroup, limit = DEFAULT_ELEMENTS_PER_PAGE } = this.state;
    this.props.deleteMulticastGroup(multicastGroup.id);
    this.setState({
      modalOpen: false,
      multicastGroup: null,
      devaddr: null,
      check: null,
    });
    setTimeout(() => {
      this.props.fetchMulticastGroups({
        qs: limit > 0 ? `limit=${limit}` : '',
      });
    }, 500);
  }

  handleChange(e, { name, value }) {
    this.setState({ [name]: value });
  }

  handlePageChange(e, { activePage }) {
    const params = cleanObject({ ...this.props.multicastGroups }, [
      'filter',
      'sort',
      'offset',
      'limit',
    ]);
    params.offset = (activePage - 1) * this.props.multicastGroups.limit;
    this.props.fetchMulticastGroups(params);
  }

  getTheNumberOfDevices() {
    const emptyArr = [];
    this.setState({ devicesNumberList: emptyArr });
  }

  render() {
    const { multicastGroups, users, usersOptions } = this.props;
    const { devaddr, check, formGroup, devicesNumberList } = this.state;

    return (
      <Fragment>
        <DefaultTable
          headers={[
            { sortable: true, name: 'Label', sortBy: 'label' },
            {
              name: 'Owner',
              sortBy: 'UserId',
              admin: userHasRole('admin'),
              key: 'owner',
            },
            { name: 'Dev Address' },
            { name: 'Devices' },
            { name: 'Actions', actions: ['edit', 'delete'] },
          ]}
          path={'multicast'}
          users={users}
          sharingTable={false}
          // applications={applications}
          devicesNumberList={devicesNumberList}
          formGroup={formGroup}
          usersOptions={usersOptions}
          handleDeleteModal={this.handleDeleteModal}
          fetch={this.fetch}
          items={multicastGroups}
        />

        <Modal
          open={this.state.modalOpen}
          onClose={this.handleClose}
          basic
          size="small"
        >
          <div style={{ width: '40%' }}>
            <SVG src="/assets/images/robotRYS.svg" />
          </div>
          <Header as="h1" content="Are you sure??" />
          <Modal.Content>
            <h3>This action will delete this multicast group.</h3>
            <h4>WARNING, THIS ACTION IS NOT REVERSIBLE!</h4>
            <p>To proceed, please fill the field with the device address</p>
            <Label size="big" color="orange" style={{ marginBottom: '2em' }}>
              {devaddr}
            </Label>
            <div>
              <Input
                name="check"
                placeholder="..."
                width={8}
                onChange={this.handleChange}
              />
            </div>
          </Modal.Content>
          <Modal.Actions>
            <Button
              onClick={this.handleDelete}
              color="red"
              disabled={devaddr !== check}
            >
              Proceed
            </Button>
            <Button onClick={this.handleClose}>Cancel</Button>
          </Modal.Actions>
        </Modal>
      </Fragment>
    );
  }
}

DvTable.propTypes = {
  multicastGroups: PropTypes.object,
  fetchMulticastGroups: PropTypes.func,
  destroyMulticastGroups: PropTypes.func,
  destroyMulticastGroup: PropTypes.func,
  deleteMulticastGroup: PropTypes.func,
  fetchUsersOptions: PropTypes.func,
  fetchUsers: PropTypes.func,
  offset: PropTypes.string,
  users: PropTypes.object,
  usersOptions: PropTypes.array,
};

const DevicesTableMulticastGroups = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(DvTable),
);

export { DevicesTableMulticastGroups };
