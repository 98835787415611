/** @format */
import React from 'react';
import { Icon } from 'semantic-ui-react';

import PropTypes from 'prop-types';

const Supports32bitFCnt = ({ item }) => {
  const { supports32bitFCnt = false } = item;

  const name = supports32bitFCnt ? 'check' : 'cancel';
  const color = supports32bitFCnt ? 'green' : 'red';

  return (
    <>
      <Icon color={color} name={name} size="large" />
    </>
  );
};

Supports32bitFCnt.propTypes = {
  item: PropTypes.object.isRequired,
};

export { Supports32bitFCnt };
