/** @format */
import idx from 'idx';
import React from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import PropTypes from 'prop-types';

import { EMPTY_STRING_VALUE, formatKeys } from '../../../lib/utilities';
import { toast } from 'react-semantic-toasts';

const Deveui = ({ item }) => {
  return (
    <span
      className="copy-to-clipboard"
      onClick={() =>
        toast({
          title: 'COPIED TO CLIPBOARD',
          description:
            'deveui' in item ? item.deveui : item.device.deveui || null,
          color: 'teal',
          type: 'success',
          icon: 'copy',
        })
      }
    >
      <CopyToClipboard
        text={'deveui' in item ? item.deveui : item.device.deveui || null}
      >
        <span>
          {idx(
            item,
            _ =>
              formatKeys(item.device ? _.device.deveui : _.deveui) ||
              EMPTY_STRING_VALUE,
          )}
        </span>
      </CopyToClipboard>
    </span>
  );
};

Deveui.propTypes = {
  item: PropTypes.object,
};

export { Deveui };
