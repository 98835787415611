/** @format */
import idx from 'idx';
import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import PropTypes from 'prop-types';

import { userHasRole } from '../../../lib/auth';

const SharingBy = ({ item, path }) => {
  return (
    <>
      {userHasRole('admin') ? (
        <>
          <Link to={`user/${item.guest.id}`}>
            {idx(item, _ => _.guest.username)}
          </Link>
          <FontAwesomeIcon
            icon="arrow-left"
            className="icon"
            style={{
              marginRight: '5%',
              marginLeft: '5%',
            }}
          />
          <Link to={`user/${item[path].owner.id}`}>
            {idx(item, _ => _[path].owner.username)}
          </Link>
        </>
      ) : (
        <>
          {idx(item, _ => _[path].owner.username)}{' '}
          <FontAwesomeIcon icon="arrow-left" className="icon" />{' '}
          {idx(item, _ => _.guest.username)}
        </>
      )}
    </>
  );
};

SharingBy.propTypes = {
  item: PropTypes.object.isRequired,
  path: PropTypes.string.isRequired,
};

export { SharingBy };
