/** @format */
import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import PropTypes from 'prop-types';

const Action = ({ item, onClickDelete }) => {
  return (
    <>
      <Link to="#" onClick={() => onClickDelete(item)}>
        <FontAwesomeIcon icon="trash-alt" />
      </Link>
    </>
  );
};

Action.propTypes = {
  item: PropTypes.object.isRequired,
  onClickDelete: PropTypes.func.isRequired,
};

export { Action };
