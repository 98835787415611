/** @format */

import React from 'react';
import { Link } from 'react-router-dom';

import PropTypes from 'prop-types';

import { PAGES_PATH } from '../../../lib/variables';
import { EMPTY_STRING_VALUE } from '../../../lib/utilities';

const Application = ({ item }) => {
  const {
    device: {
      application: { id = null, label = EMPTY_STRING_VALUE } = {},
    } = {},
  } = item;

  // if the id is null, the link will be disabled
  const to = id ? `${PAGES_PATH}/application/${id}` : '#';

  return (
    <>
      <Link to={to}>{label}</Link>
    </>
  );
};

Application.propTypes = {
  item: PropTypes.object.isRequired,
};

export { Application };
